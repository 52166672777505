@import "../../styles/variables";

.carousel.carousel-slider {
  overflow: inherit;
  display: flex;
  justify-content: center;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: white;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  position: relative;
  align-self: center;
  right: 3.125rem;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: white;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  position: relative;
  align-self: center;
  left: 3.125rem;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .control-next.control-arrow:before {
  border: 2px solid $navy;
  border-width: 0 2px 2px 0;
  display: inline-block;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 0.338rem;
  height: 0.338rem;
}

.carousel .control-prev.control-arrow:before {
  border: 2px solid $navy;
  border-width: 0 2px 2px 0;
  display: inline-block;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  width: 0.338rem;
  height: 0.338rem;
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  transition: height 0.15s ease-in;
  min-width: 100%;
  border-radius: 10px;
}
