@font-face {
  font-family: 'Urbanist-Bold';
  src: local('Urbanist-Bold'),
    url('./assets/fonts/Urbanist-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Urbanist-SemiBold';
  src: local('Urbanist-SemiBold'),
    url('./assets/fonts/Urbanist-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Urbanist-Medium';
  src: local('Urbanist-Medium'),
    url('./assets/fonts/Urbanist-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Urbanist-Regular';
  src: local('Urbanist-Regular'),
    url('./assets/fonts/Urbanist-Regular.ttf') format('truetype');
}

html,
body {
  height: 100%;
  margin: 0;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  font-size: 16px;
}
