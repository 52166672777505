.styles_react-code-input-container__tpiKG {
  width: 26rem !important;
  height: 4rem;
  margin-top: 1rem;

  @media only screen and (max-width: 425px) {
    width: 95% !important;
  }

  @media only screen and (max-width: 375px) {
    height: 7rem !important;
  }

  .styles_react-code-input__CRulA {
    width: 100%;

    :nth-child(1) {
      height: 5.125rem !important;
      margin-right: 0.563rem !important;
      font-family: Urbanist-SemiBold !important;
      font-size: 1.5rem !important;
      line-height: 0.75rem !important;
      color: #022752 !important;
      font-weight: 600 !important;
      border-radius: 0.375rem !important;
      border: solid 0.063rem #e8e8e8 !important;

      @media only screen and (max-width: 425px) {
        width: 3rem !important;
        height: 3.875rem !important;
      }
    }
    :nth-child(2) {
      height: 5.125rem !important;
      margin-right: 0.563rem !important;
      font-family: Urbanist-SemiBold !important;
      font-size: 1.5rem !important;
      line-height: 0.75rem !important;
      color: #022752 !important;
      font-weight: 600 !important;
      border-radius: 0.375rem !important;
      border: solid 0.063rem #e8e8e8 !important;

      @media only screen and (max-width: 425px) {
        width: 3rem !important;
        height: 3.875rem !important;
      }
    }
    :nth-child(3) {
      height: 5.125rem !important;
      margin-right: 0.563rem !important;
      font-family: Urbanist-SemiBold !important;
      font-size: 1.5rem !important;
      line-height: 0.75rem !important;
      color: #022752 !important;
      font-weight: 600 !important;
      border-radius: 0.375rem !important;
      border: solid 0.063rem #e8e8e8 !important;
      @media only screen and (max-width: 425px) {
        width: 3rem !important;
        height: 3.875rem !important;
      }
    }
    :nth-child(4) {
      height: 5.125rem !important;
      margin-right: 0.563rem !important;
      font-family: Urbanist-SemiBold !important;
      font-size: 1.5rem !important;
      line-height: 0.75rem !important;
      color: #022752 !important;
      font-weight: 600 !important;
      border-radius: 0.375rem !important;
      border: solid 0.063rem #e8e8e8 !important;
      @media only screen and (max-width: 425px) {
        width: 3rem !important;
        height: 3.875rem !important;
      }
    }
    :nth-child(5) {
      height: 5.125rem !important;
      margin-right: 0.563rem !important;
      font-family: Urbanist-SemiBold !important;
      font-size: 1.5rem !important;
      line-height: 0.75rem !important;
      color: #022752 !important;
      font-weight: 600 !important;
      border-radius: 0.375rem !important;
      border: solid 0.063rem #e8e8e8 !important;
      @media only screen and (max-width: 425px) {
        width: 3rem !important;
        height: 3.875rem !important;
      }
    }
    :nth-child(6) {
      height: 5.125rem !important;
      margin-right: 0.563rem !important;
      font-family: Urbanist-SemiBold !important;
      font-size: 1.5rem !important;
      line-height: 0.75rem !important;
      color: #022752 !important;
      font-weight: 600 !important;
      border-radius: 0.375rem !important;
      border: solid 0.063rem #e8e8e8 !important;
      @media only screen and (max-width: 425px) {
        width: 3rem !important;
        height: 3.875rem !important;
        margin-top: 1rem;
      }
    }
  }
}
